import React from 'react';
import { FiInstagram } from 'react-icons/fi';

import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">Sølvgade 86, 1307 Copenhagen, Denmark</p>
        <p className="p__opensans">+45 42443583</p>
        <p className="p__opensans">albert.dalum@texturerestaurant.com</p>
      </div>
      <div className="app__footer-links_logo">
        <img src={images.texture} alt="app__logo" /> <br />
        <img
          src={images.spoon}
          className="spoon__img"
          style={{ marginTop: 15 }}
        />
        <div className="app__footer-links_icons">
          <a href="https://www.instagram.com/restaurant_texture_/"> <FiInstagram /></a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Opening Hours</h1>
        <p className="p__opensans">Wednesday / Saturday</p>
        <p className="p__opensans">17:30 - 01:00</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans" style={{ textAlign: 'center' }}>2024 Texture. All Rights reserved.</p>
      <a href="https://www.findsmiley.dk/Sider/KontrolRapport.aspx?Virk6444602" className="p__opensans">https://www.findsmiley.dk/Sider/KontrolRapport.aspx?Virk6444602</a>
    </div>
  </div>
);

export default Footer;
